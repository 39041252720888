import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { AutoTooltip, DropDownSelect } from "lib/common";
import { FiChevronDown, FiChevronUp, FiChevronsRight, FiDownload, FiFileText, FiRepeat } from "react-icons/fi";
import { Link } from "react-router-dom";
import { getActiveOrganization } from "selectors/organizations";
import { getSelectedBusinessData, getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserRestrictions } from "selectors/user";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import IntegrationServices from "const/IntegrationServices";
import React, { useCallback, useMemo, useState } from "react";
import TableNew, { TableCell, TableHead, TableRow } from "lib/common/TableNew";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const DETAILS_SUB_ROWS_TYPES = {
  INCOME: "income",
  EXPENSE: "outcome"
};

const getDateRange = ([fromDate, toDate]) => {
  if (!toDate) toDate = fromDate;
  fromDate = Utils.formatApiDate(fromDate);
  toDate = Utils.formatApiDate(moment.utc(toDate).endOf("month"));

  return { fromDate, toDate };
};

const TransactionsCell = React.memo(({ data }) => {
  const businessId = useSelector(getSelectedBusinessId);

  const [{ month: lastMonth }] = data;

  const { month: firstMonth } = data[data.length - 1];

  const { unknown, total } = data.reduce(
    (aggregator, value) => {
      const { transactions: { income, outcome } } = value;

      const { count: { unknown: incomeTransactionsUnknownCount = 0, total: incomeTransactionsTotalCount = 0 } } = income;

      const { count: { unknown: outcomeTransactionsUnknownCount = 0, total: outcomeTransactionsTotalCount = 0 } } = outcome;

      aggregator.unknown += incomeTransactionsUnknownCount + outcomeTransactionsUnknownCount;
      aggregator.total += incomeTransactionsTotalCount + outcomeTransactionsTotalCount;

      return aggregator;
    },
    { unknown: 0, total: 0 }
  );

  const { fromDate, toDate } = getDateRange([firstMonth, lastMonth]);

  const query = { fromDate, toDate, status: DataConstants.STATUSES.TO_RECONCILE };

  return (total || unknown)
    ? (
      <Link to={`/${businessId}${UiRoutes.TRANSACTIONS}?${Utils.objectToQueryString(query)}`}>
        <FiRepeat />
        <span>{`${total}${unknown ? ` (${unknown}?)` : ""}`}</span>
      </Link>
    )
    : Constants.EMPTY_PLACEHOLDER;
});

const MoneyCell = React.memo(({ amount, currency }) => {
  if (!amount) return Constants.EMPTY_PLACEHOLDER;

  const content = Utils.toMoneyString(amount, currency);

  return (
    <AutoTooltip>
      <span title={content} className={CommonCss.coloredNumber} data-negative={amount < 0 ? "" : undefined}>
        {content}
      </span>
    </AutoTooltip>
  );
});

const DocumentsCell = React.memo(({ data }) => {
  const businessId = useSelector(getSelectedBusinessId);

  const [{ month: lastMonth }] = data;

  const { month: firstMonth } = data[data.length - 1];

  const total = data.reduce(
    (aggregator, value) => {
      const { documents: { income, outcome } } = value;

      const { count: { total: incomeDocumentsTotalCount = 0 } } = income;

      const { count: { total: outcomeDocumentsTotalCount = 0 } } = outcome;

      return aggregator + incomeDocumentsTotalCount + outcomeDocumentsTotalCount;
    },
    0
  );

  const { fromDate, toDate } = getDateRange([firstMonth, lastMonth]);

  const query = { fromDate, toDate, status: DataConstants.STATUSES.TO_REPORT };

  return total
    ? (
      <Link to={`/${businessId}${UiRoutes.DOCUMENTS}?${Utils.objectToQueryString(query)}`}>
        <FiFileText /><span>{total}</span>
      </Link>
    )
    : Constants.EMPTY_PLACEHOLDER;
});

const ExportToDropDown = React.memo(({
  total,
  dropUp,
  fromDate,
  toDate,
  documentsCount,
  onReportExport,
  onReportExportSettings
}) => {
  const { uiTexts } = useSelector(getTextsData);

  const userRestrictions = useSelector(getUserRestrictions);

  const options = useMemo(() => {
    return IntegrationServices.getList()
      .filter(({ fullIntegration }) => !fullIntegration)
      .map(({ label, value, documentsOnlyExport, ...restProps }) => ({
        ...restProps,
        disabled: documentsOnlyExport && !documentsCount,
        value: [value, fromDate, toDate].toString(),
        label: <>
          <FiChevronsRight />
          <span>
            {`${label} (${documentsOnlyExport || total
              ? uiTexts.documentsOnly.toLowerCase()
              : `${uiTexts.documents.toLowerCase()} & ${uiTexts.transactions.toLowerCase()}`})`}
          </span>
        </>
      }));
  }, [documentsCount, fromDate, toDate, total, uiTexts]);

  const toggleContent = useMemo(() => {
    return (
      <>
        <FiDownload />
        <span>{Utils.replaceTextVars(uiTexts.exportDateTo, { date: total ? uiTexts.documents.toLowerCase() : fromDate })}</span>
      </>
    );
  }, [fromDate, total, uiTexts]);

  const handleExportToSelectChange = useCallback((value) => {
    const [format, startDate, endDate] = value.split(",");

    const { showExportSettings = false } = IntegrationServices.getList()
      .filter(({ fullIntegration }) => !fullIntegration)
      .find((exportConfig) => {
        return exportConfig.value === format;
      });

    const exportData = { format, ...getDateRange([startDate, endDate]), ...(total ? { exclude: "transactions" } : {}) };

    if (showExportSettings) onReportExportSettings(exportData);
    else onReportExport(exportData);
  }, [total, onReportExport, onReportExportSettings]);

  const handleExportClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  return (
    <div onClick={handleExportClick}>
      <DropDownSelect
        caret
        right
        size="sm"
        theme={total ? "secondary" : "success"}
        dropup={dropUp}
        disabled={userRestrictions.reportsCreate}
        toggleClassName={toDate ? Css.footerExportSelector : undefined}
        toggleContent={toggleContent}
        options={options}
        onChange={handleExportToSelectChange} />
    </div>
  );
});

const ReportTableSubRow = React.memo(({ item, currency }) => {
  const { uiTexts } = useSelector(getTextsData);

  const {
    type,
    documents: {
      [type]: {
        count: { total: documentsTotalCount = 0 },
        amount: documentsAmount = 0
      }
    },
    transactions: {
      [type]: {
        count: { unknown: transactionsUnknownCount = 0, total: transactionsTotalCount = 0 },
        amount: transactionsAmount = 0
      }
    }
  } = item;

  return (
    <TableRow key={type} className={Css.subRow}>
      <TableCell className={Css.monthCell}>
        <div className={Css.subRowDate}>
          {type === DETAILS_SUB_ROWS_TYPES.INCOME ? uiTexts.revenue : uiTexts.expenses}
        </div>
      </TableCell>
      <TableCell className={Css.transactionsAmountCell}>
        <MoneyCell
          amount={type === DETAILS_SUB_ROWS_TYPES.INCOME ? transactionsAmount : -transactionsAmount}
          currency={currency} />
      </TableCell>
      <TableCell className={Css.transactionsCell}>
        {(transactionsTotalCount || transactionsUnknownCount)
          ? <>
            <FiRepeat />
            <span>{`${transactionsTotalCount}${transactionsUnknownCount ? ` (${transactionsUnknownCount}?)` : ""}`}</span>
          </>
          : Constants.EMPTY_PLACEHOLDER}
      </TableCell>
      <TableCell className={Css.documentsAmountCell}>
        <MoneyCell
          amount={type === DETAILS_SUB_ROWS_TYPES.INCOME ? documentsAmount : -documentsAmount}
          currency={currency} />
      </TableCell>
      <TableCell className={Css.documentsCell}>
        {documentsTotalCount
          ? <span><FiFileText /><span>{documentsTotalCount}</span></span>
          : Constants.EMPTY_PLACEHOLDER}
      </TableCell>
      <TableCell className={Css.actionsCell} />
    </TableRow>
  );
});

const ReportTableRow = React.memo((props) => {
  const { uiTexts } = useSelector(getTextsData);

  const {
    total,
    opened,
    item,
    index,
    data,
    currency,
    vatPayer,
    onClick,
    onReportExport,
    onReportExportSettings
  } = props;

  const { month, documents, transactions } = item;

  const [{ month: lastMonth }] = data;

  const { month: firstMonth } = data[data.length - 1];

  const {
    income: { amount: incomeTansactionsAmount = 0 },
    outcome: { amount: outcomeTransactionsAmount = 0 }
  } = transactions;

  const {
    income: { amount: incomeDocumentsAmount = 0, count: { total: incomeDocumentsTotalCount = 0 } },
    outcome: { amount: outcomeDocumentsAmount = 0, count: { total: outcomeDocumentsTotalCount = 0 } }
  } = documents;

  const documentsCount = incomeDocumentsTotalCount + outcomeDocumentsTotalCount;

  const itemData = useMemo(() => (total ? data : [item]), [total, data, item]);

  const subData = Object.values(DETAILS_SUB_ROWS_TYPES).map((value) => {
    return { ...item, type: value };
  });

  const handleClick = useCallback(() => onClick(month), [month, onClick]);

  return (
    <>
      <TableRow key={month} className={classNames(Css.tableRow, total && Css.total)} onClick={handleClick}>
        <TableCell className={Css.monthCell}>
          {opened ? <FiChevronUp /> : <FiChevronDown />}
          <span>
            {total
              ? uiTexts.all
              : moment(month).format(Constants.DATETIME_FORMATS.MONTH_AND_YEAR_TEXT)}
          </span>
        </TableCell>
        <TableCell className={Css.transactionsAmountCell}>
          <MoneyCell amount={incomeTansactionsAmount - outcomeTransactionsAmount} currency={currency} />
        </TableCell>
        <TableCell className={Css.transactionsCell}>
          <TransactionsCell data={itemData} />
        </TableCell>
        <TableCell className={Css.documentsAmountCell}>
          <MoneyCell amount={incomeDocumentsAmount - outcomeDocumentsAmount} currency={currency} />
        </TableCell>
        <TableCell className={Css.documentsCell}>
          <DocumentsCell data={itemData} />
        </TableCell>
        <TableCell className={Css.actionsCell}>
          <ExportToDropDown
            total={total}
            dropUp={index > (data.length >> 1)}
            fromDate={total ? firstMonth : month}
            toDate={total ? lastMonth : undefined}
            documentsCount={documentsCount}
            onReportExport={onReportExport}
            onReportExportSettings={onReportExportSettings} />
        </TableCell>
      </TableRow>
      {opened && subData.map((subItem) => {
        return (
          <ReportTableSubRow
            key={subItem.type}
            item={subItem}
            currency={currency}
            vatPayer={vatPayer} />
        );
      })}
    </>
  );
});

const ReportsTable = React.memo(({ data, disabled, onReportExport, onReportExportSettings }) => {
  const { uiTexts } = useSelector(getTextsData);

  const { currency } = useSelector(getActiveOrganization);

  const { vatPayer } = useSelector(getSelectedBusinessData);

  const [openedMonths, setOpenedMonths] = useState({});

  const handleRowClick = useCallback((month) => {
    setOpenedMonths((prev) => {
      return { ...prev, [month]: !prev[month] };
    });
  }, []);

  const summaryItem = data.reduce(
    (aggregator, item) => {
      const {
        transactions: {
          income: {
            amount: incomeTransactionsAmount = 0,
            count: { unknown: incomeTransactionsUnknownCount = 0, total: incomeTransactionsTotalCount = 0 }
          },
          outcome: {
            amount: outcomeTransactionsAmount = 0,
            count: { unknown: outcomeTransactionsUnknownCount = 0, total: outcomeTransactionsTotalCount = 0 }
          }
        },
        documents: {
          income: { amount: incomeDocumentsAmount = 0, count: { total: incomeDocumentsTotalCount = 0 } },
          outcome: { amount: outcomeDocumentsAmount = 0, count: { total: outcomeDocumentsTotalCount = 0 } }
        }
      } = item;

      aggregator.transactions.income.amount += incomeTransactionsAmount;
      aggregator.transactions.outcome.amount += outcomeTransactionsAmount;
      aggregator.transactions.income.count.unknown += incomeTransactionsUnknownCount;
      aggregator.transactions.income.count.total += incomeTransactionsTotalCount;
      aggregator.transactions.outcome.count.unknown += outcomeTransactionsUnknownCount;
      aggregator.transactions.outcome.count.total += outcomeTransactionsTotalCount;
      aggregator.documents.income.amount += incomeDocumentsAmount;
      aggregator.documents.outcome.amount += outcomeDocumentsAmount;
      aggregator.documents.income.count.total += incomeDocumentsTotalCount;
      aggregator.documents.outcome.count.total += outcomeDocumentsTotalCount;

      return aggregator;
    },
    {
      month: "total",
      transactions: {
        income: { amount: 0, count: { unknown: 0, total: 0 } },
        outcome: { amount: 0, count: { unknown: 0, total: 0 } }
      },
      documents: {
        income: { amount: 0, count: { total: 0 } },
        outcome: { amount: 0, count: { total: 0 } }
      }
    }
  );

  const summaryDocumentsCount = summaryItem.documents.income.count.total + summaryItem.documents.outcome.count.total;

  return (
    <TableNew theme="flat" className={Css.table} disabled={disabled}>
      <TableRow className={Css.tableHead}>
        <TableHead accessor="month" className={Css.monthCell}>
          <div>{uiTexts.date}</div>
        </TableHead>
        <TableHead accessor="transactionsAmount" className={Css.transactionsAmountCell}>
          {uiTexts.transactionsTotal}
        </TableHead>
        <TableHead accessor="transactions" className={Css.transactionsCell}>
          {uiTexts.transactions}
        </TableHead>
        <TableHead accessor="documentsAmount" className={Css.documentsAmountCell}>
          {uiTexts.documentsTotal}
        </TableHead>
        <TableHead accessor="documents" className={Css.documentsCell}>
          {uiTexts.documents}
        </TableHead>
        <TableHead className={Css.actionsCell}>
          {uiTexts.actions}
        </TableHead>
      </TableRow>
      {data.map((item, index) => (
        <ReportTableRow
          key={item.month}
          index={index}
          data={data}
          item={item}
          currency={currency}
          vatPayer={vatPayer}
          opened={openedMonths[item.month]}
          onClick={handleRowClick}
          onReportExport={onReportExport}
          onReportExportSettings={onReportExportSettings} />
      ))}
      {!!summaryDocumentsCount && <ReportTableRow
        total
        index={data.length}
        data={data}
        item={summaryItem}
        currency={currency}
        vatPayer={vatPayer}
        opened={openedMonths.total}
        onClick={handleRowClick}
        onReportExport={onReportExport}
        onReportExportSettings={onReportExportSettings} />}
    </TableNew>
  );
});

export default ReportsTable;
