import Css from "./style.module.scss";

import { Col, FormGroup, FormInput, InputGroup, InputGroupAddon, InputGroupText, Row } from "shards-react";
import { FiAlertCircle, FiUpload } from "react-icons/fi";
import { Form, ModalWindow } from "lib/common";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import IntegrationServices from "const/IntegrationServices";
import React, { useCallback, useMemo, useState } from "react";
import Utils from "utils/Utils";

const { ABRA_FLEXI_BEE } = IntegrationServices;

const { LS_KEYS: { ABRA_FLEXI_BEE_SETTINGS } } = Constants;

const URL_PATTERN = /^https:\/\/.+\/(flexi|c)\/.+$/;

const URL_FORMAT = "https://ACCOUNT.flexibee.eu/flexi/BUSINESS";

const INPUT_FIELD_NAMES = {
  LINK: "link",
  USERNAME: "username",
  PASSWORD: "password"
};

const ExportFlexiBeeSettingsWindow = ({ exportData: { fromDate, toDate }, onClose }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const initialState = useMemo(() => {
    const savedSettings = Utils.storageValue(`${ABRA_FLEXI_BEE_SETTINGS}_${selectedBusinessId}`)
      || Utils.storageValue(ABRA_FLEXI_BEE_SETTINGS);

    if (savedSettings && savedSettings.length) {
      const [link, username, password] = savedSettings.split(",");

      return { link, username, password, linkValid: !!link.match(URL_PATTERN) };
    }

    return { link: "", username: "", password: "", linkValid: false };
  }, [selectedBusinessId]);

  const [state, setState] = useState(initialState);

  const handleInputChange = useCallback(({ target: { name, value } }) => {
    const newState = name === INPUT_FIELD_NAMES.LINK
      ? { [name]: value, linkValid: !!value.match(URL_PATTERN) }
      : { [name]: value };

    setState({ ...state, ...newState });
  }, [state]);

  const handleClose = useCallback((result) => {
    if (result) {
      let { link, username, password } = state;

      link = link.replace(/\/flexi\//, "/c/").replace(/\/$/, "");

      Utils.storageValue(`${ABRA_FLEXI_BEE_SETTINGS}_${selectedBusinessId}`, [link, username, password].join(","));
      onClose({
        exportData: {
          format: ABRA_FLEXI_BEE.value,
          fromDate,
          toDate
        },
        requestData: { link, username, password }
      });
    }
    onClose();
  }, [selectedBusinessId, fromDate, toDate, state, onClose]);

  const modalConfig = useMemo(() => {
    const exportToText = Utils.replaceTextVars(uiTexts.exportDateTo, { date: toDate ? uiTexts.all.toLowerCase() : fromDate });

    return {
      headerText: `${exportToText} ${ABRA_FLEXI_BEE.label}`,
      confirm: true,
      okButtonText: uiTexts.export
    };
  }, [fromDate, toDate, uiTexts]);

  return (
    <ModalWindow
      applyOnEnterPress
      className={Css.exportFlexiBeeSettingsWindow}
      config={modalConfig}
      disabledOkButton={!state.linkValid || !state.username || !state.password}
      iconComponent={FiUpload}
      onClose={handleClose}>
      <Form>
        <FormGroup row>
          <div>
            <FiAlertCircle />
            <span><span>{messages.flexiBeeExportDescription}: </span><b>{URL_FORMAT}</b></span>
          </div>
        </FormGroup>
        <FormGroup row>
          <Row form>
            <Col>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText><span>{uiTexts.profileUrl}</span></InputGroupText>
                </InputGroupAddon>
                <FormInput
                  name={INPUT_FIELD_NAMES.LINK}
                  placeholder={uiTexts.enterProfileUrl}
                  value={state.link}
                  invalid={!state.linkValid}
                  onChange={handleInputChange} />
              </InputGroup>
            </Col>
          </Row>
          <Row form>
            <Col>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText><span>{uiTexts.user}</span></InputGroupText>
                </InputGroupAddon>
                <FormInput
                  name={INPUT_FIELD_NAMES.USERNAME}
                  placeholder={uiTexts.enterUserName}
                  value={state.username}
                  invalid={!state.username}
                  onChange={handleInputChange} />
              </InputGroup>
            </Col>
          </Row>
          <Row form>
            <Col>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText><span>{uiTexts.password}</span></InputGroupText>
                </InputGroupAddon>
                <FormInput
                  type="password"
                  name={INPUT_FIELD_NAMES.PASSWORD}
                  placeholder={uiTexts.enterPassword}
                  value={state.password}
                  invalid={!state.password}
                  onChange={handleInputChange} />
              </InputGroup>
            </Col>
          </Row>
        </FormGroup>
      </Form>
    </ModalWindow>
  );
};

export default ExportFlexiBeeSettingsWindow;
