import CommonCss from "lib/common/style.module.scss";

import { Card, Page, Preloader } from "lib/common";
import { CardBody } from "shards-react";
import { FiExternalLink } from "react-icons/fi";
import { checkReportsFetching, getReportsData, getUniqueReportYears } from "selectors/reports";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ExportFlexiBeeSettingsWindow from "./lib/ExportFlexiBeeSettingsWindow";
import PageActionsRow from "lib/common/PageActionsRow";
import PageHeader from "lib/common/PageHeader";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReportsActions from "actions/ReportsActions";
import ReportsFilter from "./lib/ReportsFilter";
import ReportsTable from "./lib/ReportsTable";
import Utils from "utils/Utils";
import moment from "moment";
import useEnvVars from "hooks/useEnvVars";

const NoDataContent = () => {
  const { uiTexts } = useSelector(getTextsData);

  const fetchingData = useSelector(checkReportsFetching);

  if (fetchingData) return <Preloader />;

  return (
    <div className={CommonCss.noDataContent}>
      <div>
        <div><FiExternalLink /></div>
        <div>{uiTexts.noData}</div>
      </div>
    </div>
  );
};

const ReportsPage = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const initRef = useRef(true);

  const fetchingData = useSelector(checkReportsFetching);

  const uniqueYears = useSelector(getUniqueReportYears);

  const selectedBusinessData = useSelector(getSelectedBusinessData);

  const reportsData = useSelector(getReportsData);

  const [envVars] = useEnvVars();

  const [filters, setFilters] = useState(() => {
    const validDateRange = moment(envVars.fromDate).isValid() && moment(envVars.toDate).isValid();

    return {
      fromDate: validDateRange ? envVars.fromDate : undefined,
      toDate: validDateRange ? envVars.toDate : undefined
    };
  });

  const [exportSettingsWindowData, setExportSettingsWindowData] = useState(null);

  const handleReportsFilterChange = useCallback((newFilters) => {
    setFilters(newFilters);
    history.replace(`?${Utils.objectToQueryString(filters)}`);
  }, [filters, history]);

  const handleTableReportExport = useCallback(async(exportData) => {
    const successful = await dispatch(ReportsActions.exportReport(exportData));

    if (successful) await dispatch(ReportsActions.fetchReportsList(filters));
  }, [filters, dispatch]);

  const handleTableReportExportSettings = useCallback((exportData) => {
    setExportSettingsWindowData(exportData);
  }, []);

  const handleExportSettingsWindowClose = useCallback(async(result) => {
    if (result) {
      const { exportData, requestData } = result;

      const successful = await dispatch(ReportsActions.exportReport(exportData, requestData));

      if (successful) await dispatch(ReportsActions.fetchReportsList(filters));
    }
    setExportSettingsWindowData(null);
  }, [filters, dispatch]);

  useEffect(() => {
    dispatch(ReportsActions.fetchReportsList(filters, initRef.current));
    initRef.current = false;
  }, [dispatch, filters]);

  return (
    <Page>
      <PageHeader />
      <Card>
        <CardBody>
          <PageActionsRow sticky={Object.values(filters).filter(Boolean).length}>
            <ReportsFilter
              uniqueYears={uniqueYears}
              initialValue={filters}
              disabled={fetchingData}
              onChange={handleReportsFilterChange} />
          </PageActionsRow>
          {reportsData.length
            ? (
              <ReportsTable
                businessData={selectedBusinessData}
                data={reportsData}
                disabled={fetchingData}
                onReportExport={handleTableReportExport}
                onReportExportSettings={handleTableReportExportSettings} />
            )
            : <NoDataContent />}
        </CardBody>
      </Card>
      {exportSettingsWindowData && (
        <ExportFlexiBeeSettingsWindow
          exportData={exportSettingsWindowData}
          onClose={handleExportSettingsWindowClose} />
      )}
    </Page>
  );
};

export default React.memo(ReportsPage);
